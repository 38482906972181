import React from 'react'
// import { useField } from 'formik'
// import FormError from '../FormError'

import * as styles from './input.module.css'

const Input = ({ className, type = 'text', label, ...props }) => {
  // const [field, { error, touched }] = useField(props.name!)

  return (
    <div className="relative">
      <label
        htmlFor={`form-control-${props.name}`}
        className="leading-7 text-sm text-gray-600"
      >
        {label}
      </label>
      <input
        // {...field}
        {...props}
        id={props.name}
        autoComplete="off"
        className={[
          styles.input,
          // error && touched ? styles.error : '',
          className
        ].join(' ')}
      />
      {/* <FormError visible={!!error && touched}>{error}</FormError> */}
    </div>
  )
}

export default Input
