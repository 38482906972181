import React, { useState } from 'react'
import Transition from '@/components/Transition'

import * as styles from './tabmap.module.css'

const TabMap = () => {
  const [tab, setTab] = useState(1)

  const s = (t) => [styles.tab_button, tab === t ? styles.active : ''].join(' ')

  return (
    <div className={styles.tab}>
      <div className="flex absolute top-0 w-full z-50">
        <button onClick={() => setTab(1)} className={s(1)}>
          Villa el Salvador
        </button>
        <button onClick={() => setTab(2)} className={s(2)}>
          Lurin
        </button>
      </div>
      <Transition
        show={tab === 1}
        appear={true}
        className="w-full h-full relative"
        enter="transition ease-in-out duration-700 transform order-first"
        enterStart="opacity-0 translate-x-16"
        enterEnd="opacity-100 translate-x-0"
        leave="transition ease-in-out duration-300 transform absolute"
        leaveStart="opacity-100 translate-x-0"
        leaveEnd="opacity-0 -translate-x-16"
      >
        <iframe
          title="map"
          width="100%"
          height="100%"
          scrolling="no"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          className="absolute inset-0 pt-14"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7799.096359317323!2d-76.9692435!3d-12.2111166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105bb89531d3be7%3A0x462262c87e6a3fdd!2sPRAHERSA%20S.A.C.!5e0!3m2!1ses!2spe!4v1639778762023!5m2!1ses!2spe"
        />
      </Transition>
      <Transition
        show={tab === 2}
        appear={true}
        className="w-full h-full relative"
        enter="transition ease-in-out duration-700 transform order-first"
        enterStart="opacity-0 translate-x-16"
        enterEnd="opacity-100 translate-x-0"
        leave="transition ease-in-out duration-300 transform absolute"
        leaveStart="opacity-100 translate-x-0"
        leaveEnd="opacity-0 -translate-x-16"
      >
        <iframe
          title="map"
          width="100%"
          height="100%"
          scrolling="no"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          className="absolute inset-0 pt-14"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15593.09183643887!2d-76.8175837!3d-12.2973959!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab93b3c89579eb!2sAlmac%C3%A9n%20Rivera!5e0!3m2!1ses!2spe!4v1639778872445!5m2!1ses!2spe"
        />
      </Transition>
    </div>
  )
}

export default TabMap
