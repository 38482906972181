import React from 'react'

import Seo from '@/components/Seo'
import Layout from '@/components/Layout'
import TabMap from '@/components/TabMap'
import ContactForm from '@/components/ContactForm'
import Header from '@/components/Header'
import HERO from '../images/hero_contacto.jpg'

const Contactanos = () => {
  return (
    <Layout pause>
      <Seo title="Contactanos" description='Contacta a Almacen rivera a través del numero 998226117, acercandose a nuestras oficionas o a través nuestras redes sociales' />
      <Header
        image={HERO}
        title="Contactanos"
        className="my-10 md:my-20 py-20 md:py-28 bg-top"
        desc={'Contacta a Almacen rivera a través del numero 998226117, acercandose a nuestras oficionas o a través nuestras redes sociales'}
      />
      <section className="text-gray-600 body-font relative md:mt-20">
        <div className="container px-5 py-20 mx-auto flex sm:gap-x-10 sm:flex-nowrap flex-wrap">
          <TabMap />
          <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 className="title-7 mb-1">Contactanos</h2>
            <p className="paragraph-02 mb-5 text-gray-500">
              Ingresa un correo donde podamos contactarte y darte mas
              información.
            </p>
            <ContactForm />
            <p className="text-xs text-gray-500 mt-3">
              Chicharrones blog helvetica normcore iceland tousled brook viral
              artisan.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contactanos
