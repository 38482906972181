import React from 'react'

import Input from '../Input'
import Button from '../Button'
import Textarea from '../Textarea'

import * as styles from './contact.module.css'

const ContactForm = ({ className }) => {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={[styles.contact, className].join(' ')}
    >
      <Input name="name" label="Nombre" />
      <Input name="enterprise" label="Empresa" />
      <Input type="email" name="email" label="Email" />
      <Input type="tel" name="phone" label="Teléfono" />

      <Textarea name="message" label="Mensaje" className="md:col-span-2" />

      <Button type="submit" className="md:col-span-2">
        Button
      </Button>
    </form>
  )
}

export default ContactForm
