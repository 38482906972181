import React from 'react'

const Header = ({
  image,
  desc = '',
  title = '',
  title2 = '',
  className = ''
}) => {
  return (
    <div className={['w-full relative', className].join(' ')}>
      <div className="absolute inset-0 w-full h-full">
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-50 z-20" />
        <div className="w-full h-full relative z-10">
          <img
            src={image}
            alt="Hero nosotros"
            className="absolute top-0 w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="flex flex-wrap w-full max-w-6xl px-5 mx-auto">
        <div className="lg:w-1/2 w-full mb-6 lg:mb-0  z-30">
          <h1 data-aos="fade-right" className="h2 text-gray-200">
            {title} <span className="text-red-600">{title2}</span>
          </h1>
          <div className="h-1 w-20 bg-red-600 rounded"></div>
        </div>
        <p
          data-aos="fade-left"
          className="lg:w-1/2 w-full leading-relaxed font-semibold text-white md:text-lg z-30"
        >
          {desc}
        </p>
      </div>
    </div>
  )
}

export default Header
