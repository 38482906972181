import React from 'react'

import * as styles from './textarea.module.css'

const Textarea = ({ className, name, label }) => {
  return (
    <div className={[styles.textarea, className].join(' ')}>
      <label htmlFor={`form-control-${name}`} className={styles.textarea_label}>
        {label}
      </label>
      <textarea
        name={name}
        id={`form-control-${name}`}
        className={styles.textarea_input}
      />
    </div>
  )
}

export default Textarea
